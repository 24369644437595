<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="dialog"
    title="站点经纪商平台复制"
  >
  <el-form ref="form" :model="form" size="small" label-width="120px">
      <el-form-item label="复制站点" prop="selectedSiteId">
        <el-select v-model="selectedSiteId" filterable placeholder="请选择复制站点" style="width: 300px;" @change="getplatforminfos">
          <el-option
            v-for="item in sites"
            :key="item.id"
            :label="item.siteNameEn+'-'+item.siteName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择平台信息" v-model="form.platformIds">
        <el-transfer
            v-model="form.platformIds"
            filterable
            :props="{key: 'platformId',label: 'platformName'}"
            :titles="['未选中', '已选中']"
            :data="platforminfos">
        </el-transfer>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { copyPlatforminfo } from "@/api/cms/srSite";
import { initData } from '@/api/data'
export default {
  props: {
    sup_this: {
      type: Object,
      default: null
    },
    sites: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      dialog: false,
      loading:false,
      selectedSiteId:2,
      platforminfos:[],
      form: {
        siteId: "",
        platformIds:[]
      },
    };
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    getplatforminfos(){
      const sort = 'id,desc'
      let params = { siteId:this.selectedSiteId,page: 0, size: 300, sort: sort }
      initData('community/crm/srPlatformInfo', params).then(res => {
          this.platforminfos = res.content
        }).catch(err => {
        })
    },
    doSubmit(form) {
      this.loading = true;
      copyPlatforminfo(this.form).then(res => {
        this.resetForm();
        this.$notify({
          title: '复制成功',
          type: "success",
          duration: 2500
        });
        this.loading = false;
        this.$parent.$parent.init();
      })
      .catch(err => {
        this.loading = false;
      });
    },
    resetForm() {
      this.dialog = false;
      this.$refs["form"].resetFields();
      this.form = {
        siteId: "",
        platformIds:[]
      };
    },
  }
};
</script>

<style scoped>
.logo-class{
  height: 60px;
  margin-top: 10px;
}
.site-module li{
  display: inline-block;
  margin-right: 20px;
}
.site-module img{
  height: 60px;
}
.site-module p{
  position: relative;
  left: 20px;
}
.copy-site img{
  height: 60px;
}
.copy-site span{
  margin-left: 5px;
  margin-right: 20px;
}
.copy-site{
  display: flex;
  justify-items: center;
  align-items: center;
}
</style>
