<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="dialog"
    :title="isAdd ? '新增' : '编辑'"
    :before-close="cancel"
    width="600px"
  >
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item label="站点类型" prop="siteType">
        <el-radio v-model="form.siteType" label="1">官网</el-radio>
        <el-radio v-model="form.siteType" label="2">综合社区</el-radio>
        <el-radio v-model="form.siteType" label="3">跟单社区</el-radio>
        <el-radio v-model="form.siteType" label="5">mam社区</el-radio>
      </el-form-item>
      <el-form-item label="语言版本" prop="availableLang">
        <el-radio-group v-model="form.availableLang">
          <el-radio
              v-if="form.siteType == 3"
              label="1,2"
          >全部</el-radio>
          <el-radio
              label="1"
          >中文</el-radio>
          <el-radio
              v-if="form.siteType == 3"
              label="2"
          >英文</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
          v-if="form.availableLang == '1,2'"
          label="默认语言"
          prop="defaultLang">
        <el-radio-group v-model="form.defaultLang">
          <el-radio
              label="1"
          >中文</el-radio>
          <el-radio
              label="2"
          >英文</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Logo" prop="logoUrl">
        <el-upload
          class="upload-demo"
          :action="uploadUrl"
          :on-success="handleSuccess"
          :show-file-list="false"
          >
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
        <img v-if="form.logoUrl" :src="form.logoUrl" class="logo-class">
      </el-form-item>
      <el-form-item label="站点名称" prop="siteName">
        <el-input v-model="form.siteName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="站点英文" prop="siteNameEn">
        <el-input v-model="form.siteNameEn" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="站点有效期" prop="beginTime">
        <el-date-picker
          v-model="form.beginTime"
          type="date"
          placeholder="开始日期"
          style="width: 180px;"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        至
         <el-date-picker
          v-model="form.endTime"
          type="date"
          style="width: 180px;"
          placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="访问协议" prop="accessProtocol">
        <el-radio v-model="form.accessProtocol" label="http">http</el-radio>
        <el-radio v-model="form.accessProtocol" label="https">https</el-radio>
      </el-form-item>
      <el-form-item label="域名" prop="domainName">
        <el-input v-model="form.domainName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="是否主站" prop="isMaster">
        <el-radio v-model="form.isMaster" label="1">是</el-radio>
        <el-radio v-model="form.isMaster" label="0">否</el-radio>
      </el-form-item>
      <el-form-item label="是否模板" prop="isModule">
        <el-radio v-model="form.isModule" label="1">模板站点</el-radio>
        <el-radio v-model="form.isModule" label="2">正式站点</el-radio>
      </el-form-item>
      <el-form-item label="短信平台代码" prop="smsPlatformCode">
        <el-input v-model="form.smsPlatformCode" style="width: 200px;"/>
      </el-form-item>
       <el-form-item label="短信产品代码" prop="smsProdCode">
        <el-input v-model="form.smsProdCode"  style="width: 200px;"/>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="form.status" label="1">正常</el-radio>
        <el-radio v-model="form.status" label="0">锁定</el-radio>
        <el-radio v-model="form.status" label="2">已过期</el-radio>
      </el-form-item>
      <el-form-item label="是否收费" prop="version">
        <el-radio
            v-model="form.version"
            :label="0"
            @change="versionChange"
        >免费</el-radio>
        <el-radio
            v-model="form.version"
            :label="1"
            @change="versionChange"
        >收费</el-radio>
      </el-form-item>
      <el-form-item label="优惠券" prop="couponFlag">
        <el-radio :disabled="chargeDisabled" v-model="form.couponFlag" :label="1">开启</el-radio>
        <el-radio :disabled="chargeDisabled" v-model="form.couponFlag" :label="0">关闭</el-radio>
      </el-form-item>
      <el-form-item v-if="isAdd" label="选择模板" prop="copySiteId">
        <div class="copy-site">
          <img v-if="siteModule.logoUrl" :src="siteModule.logoUrl">
          <span v-if="siteModule.siteName">{{siteModule.siteName}}</span>
          <el-button type="text" @click="chooseSiteModule">查看模板</el-button>
        </div>
      </el-form-item>
      <el-form-item label="头组件" hidden>
        <el-input v-model="form.headerModuleid"/>
      </el-form-item>
      <el-form-item label="脚组件" hidden>
        <el-input v-model="form.footerModuleid"/>
      </el-form-item>
      <el-form-item prop="wallet" label="钱包支付">
        <el-radio :disabled="chargeDisabled" v-model="form.wallet" :label="1">开启</el-radio>
        <el-radio :disabled="chargeDisabled" @change="walletCloseChange" v-model="form.wallet" :label="0">关闭</el-radio>
      </el-form-item>

      <el-form-item v-if="form.wallet == 1" prop="openCommission" label="多级返佣">
        <el-radio :disabled="chargeDisabled" v-model="form.openCommission" :label="1">开启</el-radio>
        <el-radio :disabled="chargeDisabled" v-model="form.openCommission" :label="0">关闭</el-radio>
      </el-form-item>
      <el-form-item
        v-if="form.wallet && form.openCommission == 1"
        prop="hierarchy"
        label="返佣层级数"
      >
        <el-input
          type="number"
          v-model="form.hierarchy"
          placeholder="请输入"
          style="width: 100px;"
        ></el-input>
      </el-form-item>
      <el-form-item
          v-if="form.wallet === 1"
          prop="openCommission"
          label="表现费"
      >
        <el-radio
            :disabled="chargeDisabled"
            v-model="form.openSettlement"
            :label="1"
        >开启</el-radio>
        <el-radio
            :disabled="chargeDisabled"
            v-model="form.openSettlement"
            :label="0"
        >关闭</el-radio>
      </el-form-item>
      <el-form-item v-if="form.wallet === 0" prop="aliPay" label="支付宝支付">
        <el-radio :disabled="chargeDisabled" v-model="form.aliPay" :label="1">开启</el-radio>
        <el-radio :disabled="chargeDisabled" v-model="form.aliPay" :label="0">关闭</el-radio>
      </el-form-item>
      <el-form-item v-if="form.wallet === 0" prop="weChat" label="微信支付">
        <el-radio :disabled="chargeDisabled" v-model="form.weChat" :label="1">开启</el-radio>
        <el-radio :disabled="chargeDisabled" v-model="form.weChat" :label="0">关闭</el-radio>
      </el-form-item>
      <el-form-item v-if="form.wallet === 0" prop="usdt" label="USDT">
        <el-radio :disabled="chargeDisabled" v-model="form.usdt" :label="1">开启</el-radio>
        <el-radio :disabled="chargeDisabled" v-model="form.usdt" :label="0">关闭</el-radio>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="form.sortNo" style="width: 60px;"/>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" v-model="form.remark" style="width: 370px;"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit('form')">确认</el-button>
    </div>

    <el-dialog
      title="站点模板"
      :visible.sync="dialogVisible"
      append-to-body
      width="50%">
      <div class="site-module">
        <li v-for ="item in siteModules" :key="item.id">
          <el-radio-group v-model="siteModuleId">
            <el-radio :label="item.id" >
              <img :src="item.logoUrl" :alt="item.siteName">
              <p>{{item.siteName}}</p>
            </el-radio>
          </el-radio-group>
        </li>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmsiteModule">确 定</el-button>
      </span>
    </el-dialog>
  </el-dialog>

</template>

<script>
  import { noZeroInit } from '@/utils/validate'
import { add, edit ,getSiteModules } from "@/api/cms/srSite";
import { uploadFile} from '@/api/system/dims'
import { getModulesData } from '@/api/cms/srCmsModule'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
  },
  data() {
    let _this = this;
    let validatorLogoUrl = (rule,value,callback) => {
      if(_this.form.siteType != 5){
        if (value == '') {
          return callback(new Error('请上传Logo图片'));
        }else {
          callback();
        }
      }else {
        callback();
      }
    };
    let validateCommissionLevels = (rule,value,callback) => {
      if (value === '') {
        return callback(new Error('请输入返佣层级数'));
      }
      setTimeout(() => {
        if (!noZeroInit(+value)) {
          callback(new Error('请输入正整数'));
        } else {
          callback();
        }
      }, 500);
    };

    return {
      siteModule:{},
      siteModuleId:"",
      dialogVisible: false,
      loading: false,
      dialog: false,
      siteModules:[],
      // srCmsModules: [],
      form: {
        id: "",
        siteType:'',
        availableLang:'',
        defaultLang:'',
        logoUrl:"",
        siteName: "",
        siteNameEn: "",
        domainName: "",
        accessProtocol: "",
        isMaster: "0",
        isModule:"2",
        status: "1",
        sortNo: "",
        headerModuleid:'',
        footerModuleid:'',
        copySiteId:"",
        smsPlatformCode:'',
        smsProdCode:'',
        remark: "",

        beginTime: '',
        endTime: '',

        wallet: 0,
        openCommission: 1,
        hierarchy: '',
        openSettlement: 1,
        aliPay: 0,
        weChat: 0,
        couponFlag: 0,
        usdt: 0,

      },
      rules: {
        siteType: [
          { required: true, message: "请选择站点类型", trigger: "blur" },
        ],
        availableLang: [
          { required: true, message: "请选择语言版本", trigger: "blur" },
        ],
        defaultLang: [
          { required: true, message: "请选择默认语言", trigger: "blur" },
        ],
        isModule: [
          { required: true, message: "请选择是否模板站点", trigger: "blur" },
        ],
        logoUrl: [
          { validator: validatorLogoUrl,trigger: "blur" },
        ],
        siteName: [
          { required: true, message: "请输入站点名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符(50个汉字)",
            trigger: "blur"
          }
        ],
        siteNameEn: [
          { required: true, message: "请输入站点英文", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符(50个汉字)",
            trigger: "blur"
          }
        ],
        // beginTime: [
        //   { required: true, message: '请选择开始日期', trigger: 'change' }
        // ],
        // endTime: [
        //   { required: true, message: '请选择结束日期', trigger: 'change' }
        // ],
        domainName: [
          { required: true, message: "请输入域名", trigger: "blur" },
          {
            min: 1,
            max: 200,
            message: "长度在 1 到 200 个字符(10个汉字)",
            trigger: "blur"
          }
        ],
        smsPlatformCode: [
          { required: true, message: "请输入短信平台代码", trigger: "blur" },
        ],
        smsProdCode: [
          { required: true, message: "请输入短信产品代码", trigger: "blur" },
          {
            min: 1,
            max: 200,
            message: "长度在 1 到 200 个字符(10个汉字)",
            trigger: "blur"
          }
        ],
        accessProtocol: [
          { required: true, message: "请选择访问协议", trigger: "blur" }
        ],
        isMaster: [
          { required: true, message: "请选择是否主站", trigger: "blur" }
        ],
        status: [
          { required: true, message: "请选择状态", trigger: "blur" },
        ],
        hierarchy:[
          { required: true, validator: validateCommissionLevels,/*message: '请输入返佣层级数',*/ trigger: 'blur' }
        ]
      },
      fileLists:[],
      uploadUrl:process.env.VUE_APP_API+'community/auth/file/upload',
    };
  },
  watch:{
    'form.siteType'(val,oldVal){
      if(val == 3){
        // this.form.availableLang = '1,2';
      }else {
        this.form.availableLang = '1';
      }
    }
  },
  computed: {
    chargeDisabled() {
      return this.form.version == 0;
    }
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    versionChange(e){
      console.log(e);
      if(!e){
        this.form.couponFlag = 0;
        this.form.wallet = 0;
        this.form.openCommission = 0;
        this.form.openSettlement = 0;
        this.form.aliPay = 0;
        this.form.weChat = 0;
        this.form.usdt = 0;
      }
    },
    doSubmit(form) {
      if (this.isAdd) {
        this.doAdd(form);
      } else this.doEdit(form);
    },
    doAdd(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          if(!this.form.beginTime){
            return this.$message.error('请填写开始时间')
          }
          if(!this.form.endTime){
            return this.$message.error('请填写结束时间')
          }
          this.loading = true;
          let params = {
            ...this.form,
          };
          if(params.availableLang != '1,2'){
            delete params.defaultLang;
          }
          add(params).then(res => {
              this.resetForm();
              this.$notify({
                title: res.data.message,
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.$emit('editOrAddSuccscc');
            })
            .catch(err => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          this.loading = false;
        }
      });
    },
    doEdit(form) {

      this.$refs[form].validate(valid => {
        if (valid) {
          if(!this.form.beginTime||!this.form.endTime){
            return this.$message.error('请选择站点有效期')
          }
          this.loading = true;
          let params = {
            ...this.form,
          }
          params.usdt = params.usdt ? true : false;
          if(params.availableLang != '1,2'){
            delete params.defaultLang;
          }
          edit(params).then(res => {
              console.log(res);
              this.resetForm();
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.$emit('editOrAddSuccscc');
            })
            .catch(err => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          this.loading = false;
        }
      });
    },
    resetForm() {
      this.siteModuleId='';
      this.form = {
        id: "",
        siteType:'',
        availableLang:'',
        defaultLang:'',
        logoUrl:"",
        siteName: "",
        siteNameEn: "",
        domainName: "",
        accessProtocol: "",
        isMaster: "0",
        status: "1",
        isMoudle:"2",
        sortNo: "",
        headerModuleid:'',
        footerModuleid:'',
        copySiteId:"",
        smsPlatformCode:'',
        smsProdCode:'',
        remark: "",
        beginTime: '',
        endTime:  '',

        wallet: 0,
        openCommission: 1,
        hierarchy: '',
        openSettlement: 1,
        aliPay: 0,
        weChat: 0,
      };
      this.$refs["form"] && this.$refs["form"].clearValidate();
      this.dialog = false;
    },
    // getModules(){
    //   getModulesData().then(res => {
    //      for (let i = 0; i < res.content.length; i++) {
    //        this.srCmsModules.push({
    //          id: res.content[i].id,
    //          label: res.content[i].moduleName + "-" + res.content[i].moduleDesc,
    //        });
    //      }
    //   })
    // },
    handleSuccess(response){
        //上传成功要处理的事

        this.form.logoUrl=response.data.content[0].url;
    },
    chooseSiteModule(){
      this.dialogVisible=true;
      let params = {'siteType':this.form.siteType}
      getSiteModules(params).then(res => {
        this.siteModules=res.data;
      })
    },
    confirmsiteModule(){
      this.dialogVisible=false;
      this.form.copySiteId=this.siteModuleId;
      for(let item in this.siteModules){
        if(this.siteModules[item].id==this.siteModuleId){
          this.siteModule=this.siteModules[item];
          break;
        }
      }
    },
    walletCloseChange(){
      this.form.aliPay = 1;
      this.form.weChat = 1;
    }
  }
};
</script>

<style scoped>
.logo-class{
  height: 60px;
  margin-top: 10px;
}
.site-module li{
  display: inline-block;
  margin-right: 20px;
}
.site-module img{
  height: 60px;
}
.site-module p{
  position: relative;
  left: 20px;
}
.copy-site img{
  height: 60px;
}
.copy-site span{
  margin-left: 5px;
  margin-right: 20px;
}
.copy-site{
  display: flex;
  justify-items: center;
  align-items: center;
}
</style>
