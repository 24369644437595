<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-input
      v-model="query.siteName"
      clearable
      placeholder="站点名称"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-input
      v-model="query.siteNameEn"
      clearable
      placeholder="站点英文"
      style="width: 200px;"
      class="filter-item"
      @keyup.enter.native="toQuery"
    />
    <el-select
      v-model="query.type"
      clearable
      placeholder="站点类型"
      class="filter-item"
      style="width: 130px"
      @change="toQuery"
    >
      <el-option v-for="item in typeOption" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-select
      v-model="query.charge"
      clearable
      placeholder="是否收费"
      class="filter-item"
      style="width: 130px"
      @change="toQuery"
    >
      <el-option v-for="item in chargeOption" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-select
      v-model="query.status"
      clearable
      placeholder="状态"
      class="filter-item"
      style="width: 130px"
      @change="toQuery"
    >
      <el-option
        v-for="item in statusOption"
        :key="item.key"
        :label="item.display_name"
        :value="item.key"
      />
    </el-select>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button  v-if="checkPermission(['ADMIN'])" class="filter-item" size="mini" type="danger" icon="el-icon-search" @click="toInitConfig">初始化站点配置</el-button>
    <!-- 新增 -->
    <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','SRSITE_ALL','SRSITE_CREATE'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="toAdd">新增</el-button>
      <eForm ref="form" :is-add="true"/>
    </div>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      typeOption:[
        { key: 1, display_name: '官网' },
        { key: 2, display_name: '综合社区' },
        { key: 3, display_name: '跟单社区' },
        { key: 5, display_name: 'mam多账户' },
      ],
      chargeOption:[
        { key: 1, display_name: '是' },
        { key: 0, display_name: '否' },
      ],
      statusOption:[
        { key: 1, display_name: '正常' },
        { key: 0, display_name: '锁定' },
        { key: 2, display_name: '已过期' },
      ],
    }
  },
  methods: {
    checkPermission,
    toInitConfig(){
      this.$parent.toInitConfig()
    },
    toQuery() {
      this.$parent.page = 0;
      this.$parent.init()
    },
    toAdd(){
      this.$refs.form.dialog = true;
      // this.$refs.form.getModules();
    }
  }
}
</script>
