import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'cms/crm/srSiteConfig',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'cms/crm/srSiteConfig/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'cms/crm/srSiteConfig',
    method: 'put',
    data
  })
}

// 获取所有的站点
export function getSiteConfig(params) {
  return request({
    url: '/cms/crm/srSiteConfig/getOne',
    method: 'get',
    params: params
  })
}


// 获取订阅设置
export function getSubSiteOne(params) {
  return request({
    url: 'community/crm/srCommunityRule/getSiteOne',
    method: 'get',
    params: params
  })
}


// 修改订阅设置
export function updatesubSite(data) {
  return request({
    url: 'community/crm/srCommunityRule/updateForSite',
    method: 'put',
    data
  })
}

// 查看是否订阅
export function getSubsiteRule(params) {
  return request({
    url: 'community/crm/srCommunityRule',
    method: 'get',
    params
  })
}

// 功能配置的编辑
export function editSiteFeature(data) {
  return request({
    url: 'cms/crm/updateSrSiteConfig',
    method: 'PUT',
    data
  })
}

// 查询站点支付方式
export function querySrSitePayType(data) {
  return request({
    url: 'community/crm/srSitePayType',
    method: 'get',
    params: data
  })
}

// 返佣级别编辑
// 以及删除和增加
export function updateLevel(data) {
  return request({
    url: 'community/crm/updateLevel',
    method: 'post',
    data
  })
}

export function deleteLevel(data) {
  return request({
    url: 'community/crm/deleteLevel',
    method: 'post',
    data
  })
}

export function addLevel(data) {
  return request({
    url: 'community/crm/addLevel',
    method: 'post',
    data
  })
}
