<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form" :sup_this="sup_this" :is-add="false"/>
  </div>
</template>
<script>
import eForm from './form'
import { parseTime } from '../../../utils'

export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
  },
  methods: {
    to() {
      const _this = this.$refs.form;
      _this.form = {
        id: this.data.id,
        siteType:this.data.siteType.toString(),
        logoUrl: this.data.logoUrl,
        siteName: this.data.siteName,
        siteNameEn: this.data.siteNameEn,
        domainName: this.data.domainName,
        accessProtocol: this.data.accessProtocol,
        isMaster: this.data.isMaster.toString(),
        status: this.data.status.toString(),
        isModule: this.data.isModule.toString(),
        sortNo:this.data.sortNo,
        headerModuleid:this.data.headerModuleid,
        footerModuleid:this.data.footerModuleid,
        copySiteId:this.data.copySiteId,
        smsPlatformCode:this.data.smsPlatformCode,
        smsProdCode:this.data.smsProdCode,
        remark: this.data.remark,

        beginTime: parseTime(this.data.beginTime),
        endTime:  parseTime(this.data.endTime),
        version: this.data.version,

        wallet: this.data.wallet ? 1: 0,
        commission: this.data.openCommission ? 1: 0,
        commissionLevels: this.data.hierarchy,
        fees: this.data.openSettlement,
        alipay: this.data.aliPay ? 1: 0,
        weChat: this.data.weChat ? 1: 0,
      }
      // this.$refs.form.getModules();
      _this.dialog = true
    },
    cmsConfigFun(){
      this.$router.push({
        name: "cmsconfig",
        params: {
          siteId: this.data.id,
        }
      });
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
