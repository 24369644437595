<template>
  <div>
    <!-- <el-button size="mini" type="success" @click="cmsConfigFun">配置站点</el-button> -->
    <el-button size="mini" type="warning" @click="to">经纪商配置</el-button>
    <eForm ref="form" :sup_this="sup_this" :sites="sites"/>
  </div>
</template>
<script>
import eForm from './platformSetDialog'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        siteId: this.data.id,
        platformIds:[]
      }
      _this.selectedSiteId = this.sites[0].id
       _this.getplatforminfos()
      _this.dialog = true
    },
    cmsConfigFun(){
      this.$router.push({
        name: "cmsconfig",
        params: {
          siteId: this.data.id,
        }
      });
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
