<template>
  <div class="app-container sites-manage">
    <eHeader :query="query" :sites="sites"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="ID" width="60px"/>
      <el-table-column label="站点类型" width="80px">
        <template slot-scope="scope">
          <span>{{getType(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="siteName" label="站点名称" />
      <el-table-column prop="siteNameEn" label="站点英文" />
      <el-table-column prop="accessProtocol" label="访问协议" width="80px"/>
      <el-table-column prop="domainName" label="域名" />
      <el-table-column label="状态" width="80px">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否模板" width="80px">
        <template slot-scope="scope">
          <span>{{scope.row.isMoudle==1?'是':'否'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否收费" width="80px">
        <template slot-scope="scope">
          <span>{{scope.row.version == 1?'是':'否'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" >
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作"  align="center">
        <template slot-scope="scope">
<!--          <editBtn-->
<!--            v-if="checkPermission(['ADMIN','SRSITE_ALL','SRSITE_EDIT'])"-->
<!--            :data="scope.row"-->
<!--            :sup_this="sup_this"-->
<!--          />-->
          <el-button
            v-if="checkPermission(['ADMIN','SRSITE_ALL','SRSITE_EDIT'])"
            size="mini"
            type="success"
            class="filter-item"
            @click="to(scope.row)"
          >编辑</el-button>
           <el-button
            type="primary"
            size="mini"
            class="my-button filter-item"
            v-if="checkPermission(['ADMIN','SRSITE_ALL','SRSITE_EDIT','SrSiteConfig_ALL','SrSiteConfig_EDIT'])"
            @click="openCommonConfig(scope.row.id)"
          >站点配置</el-button>
          <paltformSet v-if="checkPermission(['ADMIN','SRSITE_ALL','SRSITE_COPY'])" :data="scope.row" :sup_this="sup_this" :sites="sites"/>
          <el-popover
            v-if="checkPermission(['ADMIN','SRSITE_ALL','SRSITE_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
    <eForm
      ref="form"
      :sup_this="sup_this"
      :is-add="false"
      @editOrAddSuccscc="editOrAddSuccscc"
    />
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { getSrCmsModuleContent } from "@/api/cms/srCmsModuleContent";
import { edit } from "@/api/cms/srSiteConfig";
import { del,getSitesData } from '@/api/cms/srSite'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/cms/site/header'
import eForm from '@/components/cms/site/form'
import editBtn from '@/components/cms/site/edit'
import paltformSet from '@/components/cms/site/paltformSet'

export default {
  name:'sitesManage',
  components: {
    eHeader,
    editBtn,
    paltformSet,
    eForm,
  },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      sites: [],
    }
  },
  created() {
    this.getSites();
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    to(row) {
      const _this = this.$refs.form;
      console.log(this.getLanguage(row));
      _this.form = {
        id: row.id,
        siteType:row.siteType.toString(),
        availableLang: this.getLanguage(row),
        defaultLang: row.defaultLang + '',
        logoUrl: row.logoUrl,
        siteName: row.siteName,
        siteNameEn: row.siteNameEn,
        domainName: row.domainName,
        accessProtocol: row.accessProtocol,
        isMaster: row.isMaster.toString(),
        status: row.status.toString(),
        isModule: row.isModule.toString(),
        sortNo:row.sortNo,
        headerModuleid:row.headerModuleid,
        footerModuleid:row.footerModuleid,
        copySiteId:row.copySiteId,
        smsPlatformCode:row.smsPlatformCode,
        smsProdCode:row.smsProdCode,
        remark: row.remark,

        beginTime: parseTime(row.beginTime),
        endTime:  parseTime(row.endTime),
        version: row.version,

        wallet: row.wallet ? 1: 0,
        openCommission: row.openCommission ? 1: 0,
        hierarchy: row.hierarchy,
        openSettlement: row.openSettlement,
        aliPay: row.aliPay ? 1: 0,
        weChat: row.weChat ? 1: 0,
        couponFlag: row.couponFlag ? 1: 0,
        usdt: row.usdt ? 1: 0,
      }
      if(row.version == 0){
        _this.form.couponFlag = 0;
        _this.form.wallet = 0;
        _this.form.openCommission = 0;
        _this.form.openSettlement = 0;
        _this.form.aliPay = 0;
        _this.form.weChat = 0;
        _this.form.usdt = 0;
      }
      // this.$refs.form.getModules();
      _this.dialog = true;
    },
    getLanguage(row){
      if(row.availableLang){
        return row.availableLang;
      }else {
        if(row.siteType == 3){
          return '1,2';
        }else {
          return '1';
        }
      }
    },
    getStatus(row){
      switch (row.status) {
        case 1:
          return '正常';
        case 0:
          return '锁定';
        case 2:
          return '已过期';
        default:
          return '正常';
      }
    },
    getType(row){
      if(row.siteType == 1){
        return '官网';
      }else if(row.siteType == 2){
        return "综合社区";
      }else if(row.siteType == 3){
        return "跟单社区";
      }else if(row.siteType == 5){
        return "mam多账户"
      }else {
        return ''
      }
    },
    beforeInit() {
      this.url = 'cms/crm/srSite'
      const query = this.query
      const siteName = query.siteName
      const siteNameEn = query.siteNameEn
      const sort = 'id,desc'
      this.params = {
        'siteName':siteName,
        'siteNameEn':siteNameEn,
        page: this.page,
        size: this.size,
        sort: sort,
        siteType: this.query.type,
        status: this.query.status,
        version: this.query.charge,
      }
      return true
    },
    openCommonConfig(siteId) {
      this.$router.push({
        name: "commonConfig",
        query: {
          siteId: siteId,
        }
      });
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    },
    getSites() {
      const params = {page:0,size:1000}
      getSitesData(params).then(res => {
        this.sites = res.content;
      });
    },
    getSrCmsModuleContent(siteId,siteName,domainName){
       let params = { page: '0', size: '200', sort: "id,desc",siteId:siteId};
       getSrCmsModuleContent(params).then(res => {
        if(res.totalElements != 0){
           let siteInfo = {siteId:siteId,themeType:6,siteName:siteName}
           siteInfo.domainName = domainName.substring(domainName.indexOf('{')+1,domainName.indexOf('}'))
          for(let i=0;i<res.totalElements;i++) {
             let moduleInfo = res.content[i]
            if(moduleInfo.moduleName=='footer'){
              let moduleContent = JSON.parse(moduleInfo.moduleContent)
              siteInfo.companyIntro = moduleContent.footerTop.left.logoImgBotFont,
              siteInfo.qqCustService = moduleContent.rightContactList.serviceQQ,
              siteInfo.custServicePhone = moduleContent.rightContactList.serviceTell,
              siteInfo.icon = moduleContent.siteInfo.icon,
              siteInfo.weixinQcodePub = moduleContent.footerTop.erweiimg,
              siteInfo.weixinQcodeCustService = moduleContent.rightContactList.WeChatOfficialAccountQrcodeUrl,
              siteInfo.footBarLogo = moduleContent.footerTop.left.logoImgUrl,
              siteInfo.themeColor= moduleContent.theme.themeColor,
              siteInfo.secondThemeColor= moduleContent.theme.secondthemeColor,
              siteInfo.headerBgc= moduleContent.theme.headerBgc,
              siteInfo.footerBgc= moduleContent.theme.footerBgc,
              siteInfo.rightBarBackgroundColor=moduleContent.theme.rightBarBackgroundColor,
              siteInfo.statisticsCode = moduleContent.statisticsCode,
              siteInfo.language= 'zh_CN'
            }else if(moduleInfo.moduleName=='header'){
              let moduleContent = JSON.parse(moduleInfo.moduleContent)
              siteInfo.navibarLogo = moduleContent.logoUrl
            }else if(moduleInfo.moduleName=='newHomebanner'){
              let moduleContent = JSON.parse(moduleInfo.moduleContent)
              siteInfo.bannerBg = moduleContent.bannerUrl
            }else if(moduleInfo.moduleName=='introduce'){
              let moduleContent = JSON.parse(moduleInfo.moduleContent)
              siteInfo.teamIntro = moduleContent.desc1.substring(moduleContent.desc1.indexOf('\n')+1)
              for(let j=0;j<moduleContent.listP.length;j++){
                if(moduleContent.listP[j].title=='愿景：'){
                  siteInfo.mission = moduleContent.listP[j].content
                }else if(moduleContent.listP[j].title=='目标：'){
                  siteInfo.goal = moduleContent.listP[j].content
                }else if(moduleContent.listP[j].title=='使命：'){
                  siteInfo.expectation = moduleContent.listP[j].content
                }
              }
            }else if(moduleInfo.moduleName=='contactUs'){
              let moduleContent = JSON.parse(moduleInfo.moduleContent)
              for(let j=0;j<moduleContent.leftList.length;j++){
                if(moduleContent.leftList[j].title=='邮箱地址'){
                  siteInfo.custServiceEmail = moduleContent.leftList[j].content
                }else if(moduleContent.leftList[j].title=='工作时间'){
                  siteInfo.workTime = moduleContent.leftList[j].content
                }
              }
            }
          }
          this.doEdit(siteInfo)
        }
      });
    },
    doEdit(siteInfo) {
      edit(siteInfo).then(res => {
        }).catch(err => {
          this.$notify({
            title: "修改失败" + siteInfo.siteId,
            type: "error",
            duration: 2500
          });
        });
     },
    toInitConfig(){
       for(let i=0;i< this.sites.length;i++) {
         this.getSrCmsModuleContent(this.sites[i].id,this.sites[i].siteName,this.sites[i].domainName)
       }

    },
    editOrAddSuccscc(){
      this.init();
    }
  }
}
</script>

<style lang="less" scoped>
.sites-manage {
  .filter-item {
    margin-bottom: 5px;
  }
  .my-button {
    margin-right: 5px;
  }
}
</style>
